import { RouteRecordRaw } from "vue-router";

export const merchantAppRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/merchant",
		component: () => import("@/merchant/layout/Layout.vue"),
		meta: {
			type: "app",
		},
		children: [
			{
				path: "",
				name: "merchant-empty",
				component: () => import("@/merchant/views/Welcome.vue"),
			},
			{
				path: "welcome",
				name: "merchant-welcome",
				component: () => import("@/merchant/views/Welcome.vue"),
			},
			{
				path: "no-permission",
				name: "merchant-no-permission",
				component: () => import("@/merchant/views/NoPermission.vue"),
			},
			{
				path: "profile",
				name: "merchant-profile",
				component: () => import("@/merchant/views/Profile.vue"),
			},
			{
				path: "dashboard",
				children: [
					{
						path: "",
						name: "merchant-dashboard",
						component: () => import("@/merchant/views/dashboard/Dashboard.vue"),
					},
					{
						path: "payments-details",
						name: "merchant-dashboard-payments-details",
						component: () => import("@/merchant/views/dashboard/PaymentsDetails.vue"),
					},
				],
			},
			{
				path: "pos",
				children: [
					{
						path: "",
						name: "merchant-pos",
						component: () => import("@/merchant/views/pos/POS.vue"),
					},
					{
						path: "appointments-reminder",
						name: "merchant-pos-appointments-reminder",
						component: () => import("@/merchant/views/pos/AppointmentsReminder.vue"),
					},
					{
						path: "no-show-reschedule",
						name: "merchant-pos-no-show-reschedule",
						component: () => import("@/merchant/views/pos/NoShowReschedule.vue"),
					},
					{
						path: "rate-us-for-issued-invoices",
						name: "merchant-pos-rate-us-for-issued-invoices",
						component: () => import("@/merchant/views/pos/RateUsForIssuedInvoices.vue"),
					},
					{
						path: "customer-account-balance-reminder",
						name: "merchant-pos-customer-account-balance-reminder",
						component: () => import("@/merchant/views/pos/CustomerAccountBalanceReminder.vue"),
					},
					{
						path: "birthday-congratulations",
						name: "merchant-pos-birthday-congratulations",
						component: () => import("@/merchant/views/pos/BirthdayCongratulations.vue"),
					},
					{
						path: "sell-gift-card",
						name: "merchant-pos-sell-gift-card",
						component: () => import("@/merchant/views/pos/SellGiftCard.vue"),
					},
				],
			},
			{
				path: "appointments",
				children: [
					{
						path: "",
						name: "merchant-appointments",
						component: () => import("@/merchant/views/appointments/Appointments.vue"),
					},
					{
						path: "add-appointment",
						name: "merchant-appointments-add-appointment",
						component: () => import("@/merchant/views/appointments/AddAppointment.vue"),
					},
				],
			},
			{
				path: "reports",
				children: [
					{
						path: "general-reports",
						children: [
							{
								path: "",
								name: "merchant-reports-general-reports",
								component: () => import("@/merchant/views/reports/general-reports/GeneralReports.vue"),
							},
							{
								path: "appointments-report",
								name: "merchant-reports-general-reports-appointments-report",
								component: () => import("@/merchant/views/reports/general-reports/AppointmentsReport.vue"),
							},
							{
								path: "checkin-checkout-report",
								name: "merchant-reports-general-reports-checkin-checkout-report",
								component: () => import("@/merchant/views/reports/general-reports/CheckInCheckoutReport.vue"),
							},
						],
					},
					{
						path: "inventory-reports",
						children: [
							{
								path: "",
								name: "merchant-reports-inventory-reports",
								component: () => import("@/merchant/views/reports/inventory-reports/InventoryReports.vue"),
							},
							{
								path: "inventory-status",
								name: "merchant-reports-inventory-reports-inventory-status",
								component: () => import("@/merchant/views/reports/inventory-reports/InventoryStatus.vue"),
							},
							{
								path: "inventory-movements",
								name: "merchant-reports-inventory-reports-inventory-movements",
								component: () => import("@/merchant/views/reports/inventory-reports/InventoryMovements.vue"),
							},
							{
								path: "internal-use-report",
								name: "merchant-reports-inventory-reports-internal-use-report",
								component: () => import("@/merchant/views/reports/inventory-reports/InternalUse.vue"),
							},
							{
								path: "most-used-products",
								name: "merchant-reports-inventory-reports-most-used-products",
								component: () => import("@/merchant/views/reports/inventory-reports/MostUsedProducts.vue"),
							},
							{
								path: "most-sold-products",
								name: "merchant-reports-inventory-reports-most-sold-products",
								component: () => import("@/merchant/views/reports/inventory-reports/MostSoldProducts.vue"),
							},
						],
					},
					{
						path: "management-reports",
						children: [
							{
								path: "",
								name: "merchant-reports-management-reports",
								component: () => import("@/merchant/views/reports/management-reports/ManagementReports.vue"),
							},
							{
								path: "acitivities-report",
								name: "merchant-reports-management-reports-activities-report",
								component: () => import("@/merchant/views/reports/management-reports/ActivitiesReport.vue"),
							},
							{
								path: "activities-report-summary",
								name: "merchant-reports-management-reports-activities-report-summary",
								component: () => import("@/merchant/views/reports/management-reports/ActivitiesReportSummary.vue"),
							},
							{
								path: "detailed-attendence-report",
								name: "merchant-reports-management-reports-detailed-attendence-report",
								component: () => import("@/merchant/views/reports/management-reports/DetailedAttendenceReport.vue"),
							},
							{
								path: "commulative-attendence-report",
								name: "merchant-reports-management-reports-commulative-attendence-report",
								component: () => import("@/merchant/views/reports/management-reports/cummulative-attendence-report/CommulativeAttendenceReport.vue"),
							},
							{
								path: "employee-attendence-report/:employeeId",
								name: "merchant-reports-management-reports-employee-attendence-report",
								component: () => import("@/merchant/views/reports/management-reports/cummulative-attendence-report/EmployeeAttendenceReport.vue"),
							},
							{
								path: "employees-commissions-report",
								name: "merchant-reports-management-reports-employees-commissions-report",
								component: () => import("@/merchant/views/reports/management-reports/employee-commissions-report/EmployeesComissionsReport.vue"),
							},
							{
								path: "employees-commissions-report/:employeeId",
								name: "merchant-reports-management-reports-employee-commissions-report",
								component: () => import("@/merchant/views/reports/management-reports/employee-commissions-report/EmployeeComissionsReport.vue"),
							},
							{
								path: "services-report",
								name: "merchant-reports-management-reports-services-report",
								component: () => import("@/merchant/views/reports/management-reports/ServicesReport.vue"),
							},
							{
								path: "customers-discount-report",
								name: "merchant-reports-management-reports-customers-discount-report",
								component: () => import("@/merchant/views/reports/management-reports/CustomersDiscountReport.vue"),
							},
							{
								path: "unused-customers-offers-report",
								name: "merchant-reports-management-reports-unused-customers-offers-report",
								component: () => import("@/merchant/views/reports/management-reports/UnusedCustomersOffersReport.vue"),
							},
							{
								path: "active-gifts-cards",
								name: "merchant-reports-management-reports-active-gifts-cards",
								component: () => import("@/merchant/views/reports/management-reports/ActiveGiftsCards.vue"),
							},
							{
								path: "used-gifts-cards",
								name: "merchant-reports-management-reports-used-gifts-cards",
								component: () => import("@/merchant/views/reports/management-reports/UsedGiftsCards.vue"),
							},
							{
								path: "expired-gifts-cards",
								name: "merchant-reports-management-reports-expired-gifts-cards",
								component: () => import("@/merchant/views/reports/management-reports/ExpiredGiftsCards.vue"),
							},
							{
								path: "surveys-report",
								name: "merchant-reports-management-reports-surveys-report",
								component: () => import("@/merchant/views/reports/management-reports/SurveysReport.vue"),
							},
							{
								path: "complaints",
								name: "merchant-reports-management-reports-complaints-report",
								component: () => import("@/merchant/views/reports/management-reports/ComplaintsReport.vue"),
							},
							{
								path: "online-daily-visit",
								name: "merchant-reports-management-reports-online-daily-visit",
								component: () => import("@/merchant/views/reports/management-reports/OnlineDailyVisit.vue"),
							},
							{
								path: "historical-data-for-customers-types",
								name: "merchant-reports-management-reports-historical-data-for-customers-types",
								component: () => import("@/merchant/views/reports/management-reports/HistoricalDataForCustomersTypes.vue"),
							},
						],
					},
					{
						path: "accounting-reports",
						children: [
							{
								path: "",
								name: "merchant-reports-accounting-reports",
								component: () => import("@/merchant/views/reports/accounting-reports/AccountingReports.vue"),
							},
							{
								path: "daily-payments-summary",
								name: "merchant-reports-accounting-reports-daily-payments-summary",
								component: () => import("@/merchant/views/reports/accounting-reports/DailyPaymentsReport.vue"),
							},
							{
								path: "monthly-payments-summary",
								name: "merchant-reports-accounting-reports-monthly-payments-summary",
								component: () => import("@/merchant/views/reports/accounting-reports/MonthlyPaymentsReport.vue"),
							},
							{
								path: "payment-gateway-payments",
								name: "merchant-reports-accounting-reports-payment-gateway-payments",
								component: () => import("@/merchant/views/reports/accounting-reports/PaymentGatewayPayments.vue"),
							},
							{
								path: "credit-notes",
								name: "merchant-reports-accounting-reports-credit-notes",
								component: () => import("@/merchant/views/reports/accounting-reports/CreditNotes.vue"),
							},
							{
								path: "debit-notes",
								name: "merchant-reports-accounting-reports-debit-notes",
								component: () => import("@/merchant/views/reports/accounting-reports/DebitNotes.vue"),
							},
							{
								path: "profit-and-loss",
								name: "merchant-reports-accounting-reports-profit-and-loss",
								component: () => import("@/merchant/views/reports/accounting-reports/ProfitAndLoss.vue"),
							},
							{
								path: "vat-report",
								name: "merchant-reports-accounting-reports-vat-report",
								component: () => import("@/merchant/views/reports/accounting-reports/VAT.vue"),
							},
						],
					},
				],
			},
			{
				path: "marketing-tools",
				children: [
					{
						path: "",
						name: "merchant-marketing-tools",
						component: () => import("@/merchant/views/marketing-tools/MarketingTools.vue"),
					},
					{
						path: "whatsapp-marketing",
						name: "merchant-marketing-tools-whatsapp-marketing",
						component: () => import("@/merchant/views/marketing-tools/WhatsappMarketing.vue"),
					},
					{
						path: "ads-campaigns",
						name: "merchant-marketing-tools-ads-campaigns",
						component: () => import("@/merchant/views/marketing-tools/ads-campaign/AdsCampaigns.vue"),
					},
					{
						path: "ads-campaign",
						name: "merchant-marketing-tools-ads-campaign",
						component: () => import("@/merchant/views/marketing-tools/ads-campaign/AdsCampaign.vue"),
					},
					{
						path: "ads-campaign/:id",
						name: "merchant-marketing-tools-ads-campaign-exist",
						component: () => import("@/merchant/views/marketing-tools/ads-campaign/AdsCampaign.vue"),
					},
					{
						path: "ads-campaign/:id/customers-report",
						name: "merchant-marketing-tools-ads-campaign-customers-report",
						component: () => import("@/merchant/views/marketing-tools/ads-campaign/AdsCampaignCustomersReport.vue"),
					},
				],
			},
			{
				path: "customers",
				children: [
					{
						path: "",
						name: "merchant-customers",
						component: () => import("@/merchant/views/customers/Customers.vue"),
					},
					{
						path: ":id",
						name: "merchant-customers-customer",
						component: () => import("@/merchant/views/customers/Customer.vue"),
					},
				],
			},
			{
				path: "employees",
				children: [
					{
						path: "employees",
						children: [
							{
								path: "",
								name: "merchant-employees-employees",
								component: () => import("@/merchant/views/employees/employees/Employees.vue"),
							},
							{
								path: ":id",
								name: "merchant-employees-employee",
								component: () => import("@/merchant/views/employees/employees/Employee.vue"),
							},
						],
					},
					{
						path: "roles",
						children: [
							{
								path: "",
								name: "merchant-employees-roles",
								component: () => import("@/merchant/views/employees/roles/Roles.vue"),
							},
							{
								path: "role",
								name: "merchant-employees-roles-role",
								component: () => import("@/merchant/views/employees/roles/Role.vue"),
							},
							{
								path: "role/:id",
								name: "merchant-employees-roles-edit",
								component: () => import("@/merchant/views/employees/roles/Role.vue"),
							},
						],
					},
					{
						path: "employees-information",
						name: "merchant-employees-employees-information",
						component: () => import("@/merchant/views/employees/EmployeesInformation.vue"),
					},
					{
						path: "attendence-system",
						name: "merchant-employees-attendence-system",
						component: () => import("@/merchant/views/employees/attendence-system/AttendenceSystem.vue"),
					},
				],
			},
			{
				path: "menu",
				children: [
					{
						path: "categories",
						children: [
							{
								path: "",
								name: "merchant-menu-categories",
								component: () => import("@/merchant/views/menu/categories/Categories.vue"),
							},
							{
								path: "activate",
								name: "merchant-menu-categories-activate",
								component: () => import("@/merchant/views/menu/categories/Activate.vue"),
							},
						],
					},
					{
						path: "services",
						children: [
							{
								path: "",
								name: "merchant-menu-services",
								component: () => import("@/merchant/views/menu/services/Services.vue"),
							},
							{
								path: "activate-services",
								name: "merchant-menu-services-activate-services",
								component: () => import("@/merchant/views/menu/services/Activate.vue"),
							},
						],
					},
					{
						path: "offers",
						name: "merchant-menu-offers",
						component: () => import("@/merchant/views/menu/Offers.vue"),
					},
				],
			},
			{
				path: "products",
				children: [
					{
						path: "define-product",
						name: "merchant-products-define-product",
						component: () => import("@/merchant/views/products/DefineProduct.vue"),
					},
					{
						path: "active-products",
						name: "merchant-products-active-products",
						component: () => import("@/merchant/views/products/ActiveProducts.vue"),
					},
					{
						path: "add-quantity",
						name: "merchant-products-add-quantity",
						component: () => import("@/merchant/views/products/AddQuantity.vue"),
					},
					{
						path: "adjust-quantity",
						name: "merchant-products-adjust-quantity",
						component: () => import("@/merchant/views/products/AdjustQuantity.vue"),
					},
					{
						path: "internal-use",
						name: "merchant-products-internal-use",
						component: () => import("@/merchant/views/products/InternalUse.vue"),
					},
					{
						path: "stock-operations",
						name: "merchant-products-stock-operations",
						component: () => import("@/merchant/views/products/StockOperations.vue"),
					},
				],
			},
			{
				path: "accounting",
				children: [
					{
						path: "total-sales",
						children: [
							{
								path: "",
								name: "merchant-accounting-total-sales",
								component: () => import("@/merchant/views/accounting/total-sales/TotalSales.vue"),
							},
							{
								path: "credit-note",
								name: "merchant-accounting-total-sales-credit-note",
								component: () => import("@/merchant/views/accounting/total-sales/CreditNote.vue"),
							},
							{
								path: "debit-note",
								name: "merchant-accounting-total-sales-debit-note",
								component: () => import("@/merchant/views/accounting/total-sales/DebitNote.vue"),
							},
						],
					},
					{
						path: "customers-penalties",
						name: "merchant-accounting-customers-penalties",
						component: () => import("@/merchant/views/accounting/CustomersPenalties.vue"),
					},
					{
						path: "expenses",
						children: [
							{
								path: "",
								name: "merchant-accounting-expenses",
								component: () => import("@/merchant/views/accounting/expenses/Expenses.vue"),
							},
							{
								path: "expenses",
								children: [
									{
										path: "",
										name: "merchant-accounting-expenses-expenses",
										component: () => import("@/merchant/views/accounting/expenses/expenses/Expenses.vue"),
									},
									{
										path: "expense",
										name: "merchant-accounting-expenses-expenses-expense",
										component: () => import("@/merchant/views/accounting/expenses/expenses/Expense.vue"),
									},
									{
										path: "expense/:id",
										name: "merchant-accounting-expenses-expenses-expense-edit",
										component: () => import("@/merchant/views/accounting/expenses/expenses/Expense.vue"),
									},
								],
							},
							{
								path: "expected-expenses",
								children: [
									{
										path: "",
										name: "merchant-accounting-expenses-expected-expenses",
										component: () => import("@/merchant/views/accounting/expenses/expected-expenses/ExpectedExpenses.vue"),
									},
									{
										path: "expense",
										name: "merchant-accounting-expenses-expected-expenses-expense",
										component: () => import("@/merchant/views/accounting/expenses/expected-expenses/ExpectedExpense.vue"),
									},
									{
										path: "expense/:id",
										name: "merchant-accounting-expenses-expected-expenses-expense-edit",
										component: () => import("@/merchant/views/accounting/expenses/expected-expenses/ExpectedExpense.vue"),
									},
								],
							},
							{
								path: "suppliers",
								name: "merchant-accounting-expenses-suppliers",
								component: () => import("@/merchant/views/accounting/expenses/Suppliers.vue"),
							},
							{
								path: "suppliers/:id",
								name: "merchant-accounting-expenses-supplier",
								component: () => import("@/merchant/views/accounting/expenses/Supplier.vue"),
							},
						],
					},
					{
						path: "payments",
						name: "merchant-accounting-payments",
						component: () => import("@/merchant/views/accounting/Payments.vue"),
					},
					{
						path: "receivable",
						name: "merchant-accounting-receivable",
						component: () => import("@/merchant/views/accounting/Receivable.vue"),
					},
				],
			},
			{
				path: "setting",
				children: [
					{
						path: "general-setting",
						children: [
							{
								path: "",
								name: "merchant-setting-general-setting",
								component: () => import("@/merchant/views/setting/general-setting/GeneralSetting.vue"),
							},
							{
								path: "account-invoice/:uniqueId",
								name: "merchant-setting-general-setting-account-invoice",
								component: () => import("@/merchant/views/setting/general-setting/AccountInvoice.vue"),
							},
						],
					},
					{
						path: "accounting-setting",
						name: "merchant-setting-accounting-setting",
						component: () => import("@/merchant/views/setting/AccountingSetting.vue"),
					},
					{
						path: "invoice-setting",
						name: "merchant-setting-invoice-setting",
						component: () => import("@/merchant/views/setting/InvoiceSetting.vue"),
					},
					{
						path: "notifications-setting",
						name: "merchant-setting-notifications-setting",
						component: () => import("@/merchant/views/setting/NotificationsSetting.vue"),
					},
					{
						path: "online-setting",
						name: "merchant-setting-online-setting",
						component: () => import("@/merchant/views/setting/OnlineSetting.vue"),
					},
				],
			},
			{
				path: "billing",
				name: "merchant-billing",
				component: () => import("@/merchant/views/Billing.vue"),
			},
			{
				path: "on-hold-report",
				name: "merchant-on-hold-report",
				component: () => import("@/merchant/views/OnHoldReport.vue"),
			},
			{
				path: "wizard",
				name: "merchant-wizard",
				component: () => import("@/merchant/views/Wizard.vue"),
			},
			{
				path: "logout",
				name: "merchant-logout",
				component: () => import("@/merchant/views/SignIn.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/merchant",
		component: () => import("@/merchant/layout/AuthLayout.vue"),
		children: [
			{
				path: "sign-in",
				name: "merchant-sign-in",
				component: () => import("@/merchant/views/SignIn.vue"),
			},
			{
				path: "reset-password/:token",
				name: "merchant-reset-password",
				component: () => import("@/merchant/views/ResetPassword.vue"),
			},
		],
	},
];

export const merchantGeneralRoutes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/e-invoice/:uniqueId",
		name: "e-invoice",
		component: () => import("@/merchant/views/invoice/WebInvoice.vue"),
	},
	{
		path: "/:language(en|ar|)?/e-survey/:uniqueId",
		name: "e-survey",
		component: () => import("@/merchant/views/invoice/WebSurvey.vue"),
	},
	{
		path: "/:language(en|ar|)?/e-booking/:uniqueId",
		name: "e-booking",
		component: () => import("@/merchant/views/appointments/WebAppointment.vue"),
	},
	{
		path: "/:language(en|ar|)?/invoice-rated-successfully/:uniqueId",
		name: "invoice-rated-successfully",
		component: () => import("@/merchant/views/invoice/InvoiceRatedSuccessfully.vue"),
	},
	{
		path: "/:language(en|ar|)?/e-gift/:uniqueId",
		name: "e-gift",
		component: () => import("@/merchant/views/gift-card/WebGiftCard.vue"),
	},
	{
		path: "/:language(en|ar|)?/e-deposit/:uniqueId",
		name: "e-deposit",
		component: () => import("@/merchant/views/deposit/WebDeposit.vue"),
	},
];
